import React, { useContext } from 'react';
import { Formik } from 'formik';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';
import 'style.css';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { useMobile } from 'hooks';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import { ReactComponent as PickUp } from 'assets/pickup.svg';
import { ReactComponent as Verd } from 'assets/verd2.svg';
import { ReactComponent as Gift } from 'assets/gift.svg';
import { ReactComponent as Phone } from 'assets/phone-income.svg';
import { ClockIcon } from '@heroicons/react/solid';
import { UmbrellaIcon } from '@zydalabs/zac-icons-react';
import { PaidMethodsIcon } from 'utils/imagesResolver';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ORDER_STATUS } from 'constants/order';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import CheckBox from 'components/kit/CheckBox';
import getOrderStatusTranslation from 'utils/getOrderStatusTranslation';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderDate from './OrderDate';
import StatusOrder from './StatusOrder';
import PaymentStatusOrder from './PaymentStatusOrder';
import PaidThrough from './PaidThrough';
import { DELIVERY_ENUMS } from '../constants';

const ManualOrderMark = () => (
  <>
    <Phone className="w-4 h-4 z-100" data-tooltip-id="actionByTip" />
    <ReactTooltip id="actionByTip" place="left" type="dark" effect="solid">
      <Text value={translations.MANUAL_ORDER_DROPDOWN} />
    </ReactTooltip>
  </>
);

const getOrderIconColor = deliveryStatus => {
  switch (deliveryStatus) {
    case ORDER_RIDER_STATUS.SUBMITTED:
    case ORDER_RIDER_STATUS.PENDING:
      return 'orange';
    case ORDER_RIDER_STATUS.DECLINED:
      return 'red';
    case ORDER_RIDER_STATUS.ASSIGNED:
    case ORDER_RIDER_STATUS.ACCEPTED:
    case ORDER_RIDER_STATUS.PUSHED:
      return 'blue';
    case ORDER_RIDER_STATUS.DISPATCHED:
      return 'purple';
    case ORDER_RIDER_STATUS.CANCELED:
      return 'red';
    case ORDER_RIDER_STATUS.DELIVERED:
      return 'green';
    default:
      return '';
  }
};

const getOrderIcon = (orderDeliveryType, gift, deliveryStatus) => {
  let orderIcon = <PickUp />;
  if (orderDeliveryType === DELIVERY_BUSY_MODE.DELIVERY) {
    if (gift) {
      orderIcon = <Gift />;
    } else {
      orderIcon = <Verd fill={getOrderIconColor(deliveryStatus)} />;
    }
  } else if (orderDeliveryType === DELIVERY_BUSY_MODE.BEACH) {
    orderIcon = <UmbrellaIcon width="18" />;
  }
  return orderIcon;
};

const getDeliveryTypeText = orderDeliveryType => {
  let deliveryText = <Text value={translations.PICKUP} />;
  if (orderDeliveryType === DELIVERY_BUSY_MODE.DELIVERY) {
    deliveryText = <Text value={translations.DELIVERY} />;
  } else if (orderDeliveryType === DELIVERY_BUSY_MODE.BEACH) {
    deliveryText = <Text value={translations.BEACH} />;
  }
  return deliveryText;
};

const BulkChangeTip = ({ status }) => (
  <ReactTooltip id="bulkChangeTip" place="top" type="dark" effect="solid">
    <Text value={translations.CHANGING_TO} className="inline" />
    <Text value={getOrderStatusTranslation(status)} className="inline" />
  </ReactTooltip>
);

BulkChangeTip.propTypes = {
  status: PropTypes.string,
};

const OrderItemMobile = ({
  id,
  paidThrough,
  number,
  deliveryType,
  createdAt,
  branchName,
  branchData,
  total,
  isManualOrder,
  customerName,
  isSelected,
  updateOrderSelectedState = () => {
    // Do Nothing
  },
  isBulkChange = false,
  isOrderUpdating,
  bulkChangeStatus,
  isScheduled,
  expectedAt,
  timeSlot,
  isFaded,
  onClick,
  status,
  showOrderStatus,
  paymentStatus,
  gift,
  showScheduled,
  deliveryZone,
  areaNameEn,
  areaNameAr,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { currency } = selectedStore;
  const fromDate = timeSlot && timeSlot.split(',')[0];
  const toDate = timeSlot && timeSlot.split(',')[1];
  const theFromDate =
    fromDate &&
    moment(fromDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theToDate =
    toDate &&
    moment(toDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theTimeSlot = theFromDate && theToDate && `${theFromDate} - ${theToDate}`;
  const areaName = lang === 'en' ? areaNameEn : areaNameAr || areaNameEn;

  return (
    <>
      {isOrderUpdating && <BulkChangeTip status={bulkChangeStatus} />}
      <div
        role="presentation"
        className={cx(
          'flex flex-col md:flex-wrap bg-gray-100 items-center cursor-pointer py-4',
          isSelected && 'bg-primary-base/10',
          lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row',
          (isFaded || isOrderUpdating) && 'opacity-25',
        )}
        onClick={onClick}
      >
        <div
          className={cx(
            'flex justify-between w-full border-b md:border-r md:border-b-0 border-gray-300 px-4 pb-4',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <div className="flex items-center">
            {isBulkChange && !isOrderUpdating && (
              <CheckBox value={isSelected} lang={lang} onChange={value => updateOrderSelectedState(id, value)} />
            )}
            {isOrderUpdating && (
              <ClockIcon className={cx('w-5 h-5 text-zyda-yellow-500', lang === 'ar' ? 'ml-3' : 'mr-3')} />
            )}
            <OrderDate timeZone={selectedStore.timeZone} date={createdAt} />
          </div>
          <div className={cx('flex items-center justify-start', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
            {isManualOrder && <ManualOrderMark lang={lang} />}
            <div className="h-5">
              <PaidMethodsIcon paidThrough={paidThrough} width="1.25rem" height="1.25rem" />
            </div>
            <div className={cx(lang === 'ar' ? 'text-right' : 'text-left', 'text-xs text-gray-700')}>
              <span style={{ direction }} className="block">
                {total.toFixed(currency.decimals)} <Text value={currency} className="inline" />
              </span>
            </div>
          </div>
        </div>
        <div
          className={cx(
            'mt-3 px-4 w-full flex-1 flex',
            lang === 'ar' && 'flex-row-reverse',
            'flex-wrap items-center select-none',
          )}
        >
          <div
            className={cx('w-full flex items-start justify-between', lang === 'ar' ? 'flex-row-reverse' : 'flex-row ')}
          >
            <div className={cx('w-1/2', lang === 'ar' ? 'text-right' : 'text-left')}>
              <span className="block text-gray-700 text-xs">
                #<span className="select-all cursor-text">{number}</span>
              </span>
              <span className="block text-sm">{customerName}</span>
            </div>

            <div className={cx('w-1/2 flex items-center justify-end', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
              <div className="flex flex-col justify-start align-end">
                <div className={cx('flex justify-end', lang === 'ar' && 'flex-row-reverse')}>
                  <div
                    className={cx(
                      'h-5',
                      deliveryType === DELIVERY_BUSY_MODE.BEACH && (lang === 'ar' ? 'ml-1' : 'mr-1'),
                      gift && 'px-1',
                    )}
                  >
                    {getOrderIcon(deliveryType, gift)}
                  </div>
                  <span className="block text-gray-700 text-xs">{getDeliveryTypeText(deliveryType)}</span>
                </div>
                <span className={cx('block', lang === 'ar' ? 'text-left' : 'text-right')}>
                  {branchData ? translate(branchData) : branchName}

                  {// checking if store use new delivery zone system (useDeliveryzonesMs)
                  // NOTE later we will migrate all stores to new system
                  deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL &&
                    ` - ${deliveryZone?.zoneName ? deliveryZone.zoneName : areaName}`}
                </span>
              </div>
            </div>
          </div>
          <div
            className={cx(
              'w-full flex items-center justify-between mt-4',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row ',
            )}
          >
            {showScheduled && (
              <div
                className={cx(
                  'w-1/2 flex items-center justify-start',
                  lang === 'ar' ? ' flex-row-reverse' : 'flex-row',
                )}
              >
                <div className={cx(lang === 'ar' ? 'text-right' : 'text-left')}>
                  <span className="block text-gray-700 text-xs">
                    {isScheduled ? <Text value={translations.SCHEDULED} /> : <Text value={translations.ASAP} />}
                  </span>
                  <span className="block">
                    {theTimeSlot ? (
                      <>
                        <div>{theTimeSlot}, </div>
                        <div>
                          {moment(expectedAt)
                            .tz(selectedStore.timeZone)
                            .locale(lang === 'ar' ? 'ar' : 'en-gb')
                            .format('DD MMM YYYY')}
                        </div>
                      </>
                    ) : expectedAt ? (
                      moment(expectedAt)
                        .tz(selectedStore.timeZone)
                        .locale(lang === 'ar' ? 'ar' : 'en-gb')
                        .format('h:mm A, DD MMM YYYY')
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            )}
            {showOrderStatus &&
              (status !== ORDER_STATUS.PENDING ? (
                <div
                  className={cx('flex items-center justify-start', lang === 'ar' ? ' flex-row-reverse' : 'flex-row')}
                >
                  <StatusOrder status={status} lang={lang} />
                </div>
              ) : (
                <div className={cx('flex items-center justify-end', lang === 'ar' ? ' flex-row-reverse' : 'flex-row')}>
                  <PaymentStatusOrder paymentStatus={paymentStatus} lang={lang} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

OrderItemMobile.propTypes = {
  id: PropTypes.number,
  paidThrough: PropTypes.string,
  number: PropTypes.number,
  updateOrderSelectedState: PropTypes.func,
  isBulkChange: PropTypes.bool,
  isOrderUpdating: PropTypes.bool,
  isSelected: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  customerName: PropTypes.string,
  areaNameEn: PropTypes.string,
  areaNameAr: PropTypes.string,
  deliveryType: PropTypes.string,
  createdAt: PropTypes.string,
  isManualOrder: PropTypes.bool,
  branchName: PropTypes.string,
  branchData: PropTypes.shape({
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }),
  total: PropTypes.number,
  isScheduled: PropTypes.bool,
  expectedAt: PropTypes.string,
  timeSlot: PropTypes.string,
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.string,
  showOrderStatus: PropTypes.bool,
  paymentStatus: PropTypes.string,
  gift: PropTypes.string,
  showScheduled: PropTypes.bool,
  deliveryZone: PropTypes.shape({
    zoneName: PropTypes.string,
  }),
};

const OrderItemDesktop = ({
  id,
  paidThrough,
  number,
  deliveryType,
  createdAt,
  submittedAt,
  branchName,
  branchData,
  total,
  isManualOrder,
  customerName,
  isSelected,
  updateOrderSelectedState = () => {
    // Do Nothing
  },
  isBulkChange = false,
  isOrderUpdating,
  bulkChangeStatus,
  isScheduled,
  expectedAt,
  timeSlot,
  isFaded,
  onClick,
  status,
  showOrderStatus,
  paymentStatus,
  gift,
  showScheduled,
  textSize,
  showOrderingUser,
  deliveryZone,
  areaNameEn,
  areaNameAr,
  deliveryStatus,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { colors } = useTheme();
  const { currency } = selectedStore;
  const fromDate = timeSlot && timeSlot.split(',')[0];
  const toDate = timeSlot && timeSlot.split(',')[1];
  const theFromDate =
    fromDate &&
    moment(fromDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theToDate =
    toDate &&
    moment(toDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theTimeSlot = theFromDate && theToDate && `${theFromDate} - ${theToDate}`;
  const areaName = lang === 'en' ? areaNameEn : areaNameAr || areaNameEn;
  return (
    <>
      {isOrderUpdating && <BulkChangeTip status={bulkChangeStatus} />}
      <div
        role="presentation"
        data-tooltip-id={isOrderUpdating && 'bulkChangeTip'}
        className={cx(
          'flex flex-col md:flex-wrap bg-gray-100 relative',
          lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row',
          'items-center cursor-pointer',
          isSelected && 'bg-primary-base/10',
          (isFaded || isOrderUpdating) && 'opacity-25',
        )}
        onClick={onClick}
        data-testid="order-number-select"
      >
        <div
          className={cx(
            'px-6 py-3 pr-4 flex-initial md:flex-2 border-b  md:border-b-0 border-gray-200',
            lang === 'ar' ? 'md:border-l flex-row-reverse' : 'md:border-r',
            'flex flex-row justify-center items-center',
            isBulkChange ? 'w-34' : 'w-32',
          )}
        >
          {isBulkChange && !isOrderUpdating && (
            <CheckBox value={isSelected} lang={lang} onChange={value => updateOrderSelectedState(id, value)} />
          )}
          {isOrderUpdating && (
            <ClockIcon className={cx('w-5 h-5 text-zyda-yellow-500', lang === 'ar' ? 'ml-3' : 'mr-3')} />
          )}
          <OrderDate timeZone={selectedStore.timeZone} date={submittedAt || createdAt} />
        </div>

        <div
          className={cx(
            'py-4 px-4 w-full flex-1 flex',
            lang === 'ar' && 'flex-row-reverse',
            'items-center select-none justify-between',
          )}
        >
          <div
            className={cx(
              'p-2 order-4 md:order-none md:p-0 flex items-center',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
              showScheduled ? 'w-full md:w-1/5 justify-start' : 'w-1/5 justify-center',
            )}
          >
            <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
              {showOrderingUser ? (
                <>
                  <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                    #<span className="select-all cursor-text">{number}</span>
                  </Typography>
                  <div className="text-truncation">
                    <Typography variant="heading16" cursor="pointer">
                      {customerName}
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <span className="block text-gray-700 text-xs">Order No.</span>
                  <span className="block text-sm">
                    #<span className="select-all cursor-text">{number}</span>
                  </span>
                </>
              )}
            </div>
          </div>
          <div
            className={cx(
              'flex items-center justify-start',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
              showScheduled ? 'w-1/2 md:w-1/4' : 'w-35p',
            )}
          >
            <span
              className={cx(
                lang === 'ar' ? 'ml-2' : 'mr-2',
                'rounded-full bg-gray-200 w-8 h-8 px-2 flex items-center justify-center',
              )}
            >
              <span className="h-5">{getOrderIcon(deliveryType, gift, deliveryStatus)}</span>
            </span>
            <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
              <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                {getDeliveryTypeText(deliveryType)}
              </Typography>
              <Typography cursor="pointer">
                {branchData ? translate(branchData) : branchName}

                {// checking if store use new delivery zone system (useDeliveryzonesMs)
                // NOTE later we will migrate all stores to new system
                deliveryType === 'delivery' && ` - ${deliveryZone?.zoneName ? deliveryZone?.zoneName : areaName}`}
              </Typography>
            </div>
          </div>
          <div
            className={cx(
              'flex items-center',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
              showScheduled ? 'w-1/2 md:w-1/5' : 'w-1/4 justify-center',
            )}
          >
            <div>
              <PaidMethodsIcon paidThrough={paidThrough} />
            </div>
            <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left', textSize !== 'sm' && 'pl-2')}>
              <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                <PaidThrough paidThrough={paidThrough} />
              </Typography>
              <div style={{ direction }}>
                <Typography cursor="pointer">
                  {total.toFixed(currency.decimals)}
                  <Text value={currency} className="inline" />
                </Typography>
              </div>
            </div>
          </div>
          {showScheduled && (
            <div
              className={cx(
                'w-full p-2 md:w-1/4 md:p-0 flex items-center justify-start',
                lang === 'ar' ? ' flex-row-reverse' : 'flex-row',
              )}
            >
              <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
                <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                  {isScheduled ? <Text value={translations.SCHEDULED} /> : <Text value={translations.ASAP} />}
                </Typography>
                <Typography cursor="pointer">
                  {theTimeSlot ? (
                    <>
                      <div>{theTimeSlot}, </div>
                      <div>
                        {moment(expectedAt)
                          .tz(selectedStore.timeZone)
                          .locale(lang === 'ar' ? 'ar' : 'en-gb')
                          .format('DD MMM YYYY')}
                      </div>
                    </>
                  ) : expectedAt ? (
                    moment(expectedAt)
                      .tz(selectedStore.timeZone)
                      .locale(lang === 'ar' ? 'ar' : 'en-gb')
                      .format('h:mm A, DD MMM YYYY')
                  ) : (
                    ''
                  )}
                </Typography>
              </div>
            </div>
          )}
          <div
            className={cx(
              'p-2 flex items-center',
              lang === 'ar' ? ' flex-row-reverse' : 'flex-row',
              showScheduled ? `${showOrderStatus ? 'md:w-1/5' : ''}` : 'w-1/5',
            )}
          >
            {showOrderStatus ? (
              status !== ORDER_STATUS.PENDING ? (
                <StatusOrder status={status} />
              ) : (
                <PaymentStatusOrder paymentStatus={paymentStatus} />
              )
            ) : null}
          </div>
        </div>
        <div className={cx('absolute', lang === 'ar' ? 'left-6' : 'right-6')}>
          {isManualOrder && <ManualOrderMark />}
        </div>
      </div>
    </>
  );
};

OrderItemDesktop.propTypes = {
  id: PropTypes.number,
  paidThrough: PropTypes.string,
  number: PropTypes.number,
  updateOrderSelectedState: PropTypes.func,
  isBulkChange: PropTypes.bool,
  isOrderUpdating: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  customerName: PropTypes.string,
  userData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
    }),
  }),
  branchData: PropTypes.shape({
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }),
  deliveryType: PropTypes.string,
  createdAt: PropTypes.string,
  submittedAt: PropTypes.string,
  branchName: PropTypes.string,
  total: PropTypes.number,
  isManualOrder: PropTypes.bool,
  isSelected: PropTypes.bool,
  areaNameEn: PropTypes.string,
  areaNameAr: PropTypes.string,
  isScheduled: PropTypes.bool,
  expectedAt: PropTypes.string,
  timeSlot: PropTypes.string,
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.string,
  showOrderStatus: PropTypes.bool,
  paymentStatus: PropTypes.string,
  gift: PropTypes.string,
  showScheduled: PropTypes.bool,
  textSize: PropTypes.string,
  showOrderingUser: PropTypes.bool,
  deliveryStatus: PropTypes.string,
  deliveryZone: PropTypes.shape({
    zoneName: PropTypes.string,
  }),
};

const OrderItem = ({
  isOpened,
  isFaded,
  onClick,
  ordersVariables,
  newOrders,
  showOrderStatus,
  orderItemWidth,
  updateOrderSelectedState,
  isOrderUpdating,
  bulkChangeStatus,
  isBulkChange = false,
  item,
  showScheduled = true,
  showOrderingUser = true,
  textSize = 'sm',
}) => {
  const isMobile = useMobile();
  const { selectedStore, legalData } = useContext(userContext);
  const { currency } = selectedStore;
  const { isManualOrder, customerName } = item;

  return (
    <Formik
      onSubmit={(values, actions) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      <div className={cx('mb-4 rounded-md')} data-testid="order-item-block">
        {isMobile ? (
          <OrderItemMobile
            isOpened={isOpened}
            isFaded={isFaded}
            onClick={onClick}
            key={item.id}
            id={item.id}
            isManualOrder={isManualOrder}
            customerName={customerName}
            isSelected={item.isSelected}
            updateOrderSelectedState={updateOrderSelectedState}
            isOrderUpdating={isOrderUpdating}
            bulkChangeStatus={bulkChangeStatus}
            isBulkChange={isBulkChange}
            ordersVariables={ordersVariables}
            newOrders={newOrders}
            showOrderStatus={showOrderStatus}
            orderItemWidth={orderItemWidth}
            showScheduled={showScheduled}
            {...item}
          />
        ) : (
          <OrderItemDesktop
            isOpened={isOpened}
            isFaded={isFaded}
            onClick={onClick}
            key={item.id}
            id={item.id}
            deliveryStatus={item.deliveryStatus}
            isManualOrder={isManualOrder}
            customerName={customerName}
            isSelected={item.isSelected}
            updateOrderSelectedState={updateOrderSelectedState}
            isOrderUpdating={isOrderUpdating}
            bulkChangeStatus={bulkChangeStatus}
            isBulkChange={isBulkChange}
            ordersVariables={ordersVariables}
            newOrders={newOrders}
            showOrderStatus={showOrderStatus}
            orderItemWidth={orderItemWidth}
            showScheduled={showScheduled}
            textSize={textSize}
            showOrderingUser={showOrderingUser}
            {...item}
          />
        )}
        {isOpened && (
          <OrderDetails
            ordersVariables={ordersVariables}
            currency={currency}
            status={item.status}
            legalData={legalData}
            deliveryType={item.deliveryType}
            branchData={item.branchData}
            id={item.id}
            newOrders={newOrders}
            selected={selectedStore}
          />
        )}
      </div>
    </Formik>
  );
};

OrderItem.propTypes = {
  isOpened: PropTypes.bool,
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  isBulkChange: PropTypes.bool,
  isOrderUpdating: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  updateOrderSelectedState: PropTypes.func,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  showOrderStatus: PropTypes.bool,
  orderItemWidth: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    deliveryType: PropTypes.string,
    branchData: PropTypes.string,
    isSelected: PropTypes.bool,
    customerName: PropTypes.string,
    isManualOrder: PropTypes.bool,
    stateHistories: PropTypes.shape({
      actionBy: PropTypes.string,
      createdAt: PropTypes.string,
      state: PropTypes.string,
    }),
  }),
  showScheduled: PropTypes.bool,
  showOrderingUser: PropTypes.bool,
  textSize: PropTypes.string,
};

export default OrderItem;
