import React from 'react';
import PropTypes from 'prop-types';

import logoPlaceholder from 'assets/logo-placeholder.png';
import ThermalInvoiceTitle from './ThermalInvoiceTitle';
import ThermalInvoiceInfo from './ThermalInvoiceInfo';
import ThermalInvoiceUserInfo from './ThermalInvoiceUserInfo';
import ThermalInvoiceItems from './ThermalInvoiceItems';
import ThermalInvoicePricing from './ThermalInvoicePricing';
import ThermalInvoiceGift from './ThermalInvoiceGift';

class ThermalOrderInvoice extends React.PureComponent {
  render() {
    const {
      order,
      store,
      lang,
      isGift,
      name,
      direction,
      theTimeSlot,
      translate,
      address,
      paidThrough,
      legalData,
      shouldHidePricing,
    } = this.props;

    return (
      <div className="flex flex-col">
        <ThermalInvoiceTitle
          lang={lang}
          translate={translate}
          isGift={isGift}
          direction={direction}
          order={order}
          store={store}
          name={name}
          legalData={legalData}
          logoPlaceholder={logoPlaceholder}
        />
        <ThermalInvoiceInfo
          lang={lang}
          translate={translate}
          direction={direction}
          order={order}
          store={store}
          theTimeSlot={theTimeSlot}
        />
        <ThermalInvoiceUserInfo
          lang={lang}
          direction={direction}
          translate={translate}
          order={order}
          isGift={isGift}
          addressTranslation={address}
        />
        <ThermalInvoiceItems
          lang={lang}
          direction={direction}
          translate={translate}
          order={order}
          isGift={isGift}
          store={store}
          shouldHidePricing={shouldHidePricing}
        />
        {!shouldHidePricing && (
          <ThermalInvoicePricing
            lang={lang}
            direction={direction}
            translate={translate}
            isGift={isGift}
            order={order}
            store={store}
            paidThroughMethod={paidThrough}
            shouldHidePricing={shouldHidePricing}
          />
        )}

        {isGift && <ThermalInvoiceGift translate={translate} order={order} direction={direction} lang={lang} />}
      </div>
    );
  }
}

ThermalOrderInvoice.propTypes = {
  shouldHidePricing: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    discountedAmount: PropTypes.number.isRequired,
    voucherCode: PropTypes.string,
    subtotalAfterVoucher: PropTypes.number,
    deliveryType: PropTypes.string,
    deliveryFee: PropTypes.number,
    tax: PropTypes.number,
    taxPercentage: PropTypes.number,
    taxInclusive: PropTypes.bool,
    total: PropTypes.number.isRequired,
    paidThrough: PropTypes.string,
    voucherAmount: PropTypes.number,
  }),
  store: PropTypes.shape({ timeZone: PropTypes.string, currency: PropTypes.number }),
  lang: PropTypes.string.isRequired,
  isGift: PropTypes.bool,
  name: PropTypes.string,
  direction: PropTypes.string.isRequired,
  theTimeSlot: PropTypes.string,
  translate: PropTypes.func,
  address: PropTypes.shape({
    area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
  }),
  paidThrough: PropTypes.func,
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
};
export default ThermalOrderInvoice;
