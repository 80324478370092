import React, { useContext } from 'react';
import { LogosWhatsAppIcon, ChartColumnIcon } from '@zydalabs/zac-icons-react';
import { Button, Typography } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { Modal } from 'components/kit';
import { Text } from 'components/service';
import AddRiderModal from './AddRiderModal';

const RestaurantNotHasRiders = ({ mutateFetchVerdDrivers, sendChangeStatus, handleAssignDriverToOrder }) => {
  const { translate, direction } = useContext(localeContext);

  const AddRiderModalBody = (closeAddRiderModal, closeable) => (
    <AddRiderModal
      close={closeAddRiderModal}
      closeable={closeable}
      mutateFetchVerdDrivers={mutateFetchVerdDrivers}
      sendChangeStatus={sendChangeStatus}
      handleAssignDriverToOrder={handleAssignDriverToOrder}
    />
  );
  return (
    <div className="border-t pt-5 pb-4" style={{ direction }}>
      <div className="flex gap-2.5 items-start mb-2">
        <div>
          <LogosWhatsAppIcon width="20px" color="black" />
        </div>
        <Typography variant="element14">
          <Text value={translations.SEND_ORDER_THROUGH_WHATSAPP} />
        </Typography>
      </div>
      <div className="flex gap-2.5 items-start mb-5">
        <ChartColumnIcon width="20px" color="black" />
        <Typography variant="element14">
          <Text value={translations.REALTIME_UPDATES} />
        </Typography>
      </div>
      <Modal>
        {({ open: openAddRiderModal, close: closeAddRiderModal, closeable }) => (
          <div className="w-9/12">
            <Button
              variant="tertiary"
              text={translate(translations.ASSIGN_THROUGH_WHATSAPP)}
              endIcon={
                <div>
                  <LogosWhatsAppIcon width="25px" color="#196EFF" />
                </div>
              }
              rounded
              isFluid
              onClick={() =>
                openAddRiderModal({
                  body: AddRiderModalBody(closeAddRiderModal, closeable),
                })
              }
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

RestaurantNotHasRiders.propTypes = {
  mutateFetchVerdDrivers: PropTypes.func,
  sendChangeStatus: PropTypes.func,
  handleAssignDriverToOrder: PropTypes.func,
};

export default RestaurantNotHasRiders;
