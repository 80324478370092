import React from 'react';
import moment from 'moment';

import * as translations from 'constants/translations';
import A4OrderInvoice from './A4OrderInvoice';
import ThermalOrderInvoice from './ThermalOrderInvoice';
import { PAYMENT_METHODS_ENUMS } from '../Frame/utils';
import { getShouldHidePricingFlag } from 'common/utils/shouldHidePricing';
export default class extends React.PureComponent {
  translate = value => {
    const { lang } = this.props;
    const [en, ar] = value instanceof Array ? value : [value.titleEn, value.titleAr];
    return lang === 'en' ? en : lang === 'ar' ? ar : '';
  };

  Address = ({ avenue, block, building, floor, street, unitNo, unitType }) =>
    `${block ? `${this.translate(translations.BLOCK)} ${block}, ` : ''}${
      avenue ? `${this.translate(translations.AVENUE)} ${avenue}, ` : ''
    }${street ? `${this.translate(translations.STREET)} ${street}, ` : ''}${
      building ? `${this.translate(translations.BUILDING)} ${building}, ` : ''
    }${floor ? `${this.translate(translations.FLOOR)} ${floor}, ` : ''}${
      unitNo ? `${this.translate(translations.UNIT)} ${unitNo}, ` : ''
    }${unitType ? `${this.translate(translations.UNIT_TYPE)} ${unitType}` : ''}`;

  PaidThrough = paidThrough => {
    switch (paidThrough) {
      case PAYMENT_METHODS_ENUMS.BENEFIT:
        return this.translate(translations.BENEFIT);
      case PAYMENT_METHODS_ENUMS.CASH:
        return this.translate(translations.CASH);
      case PAYMENT_METHODS_ENUMS.CARD_ON_DELIVERY:
        return this.translate(translations.CARDONDELIVERY);
      case PAYMENT_METHODS_ENUMS.CREDIT_CARD:
        return this.translate(translations.CREDIT_CARD);
      case PAYMENT_METHODS_ENUMS.MEEZA:
        return this.translate(translations.MEEZA);
      case PAYMENT_METHODS_ENUMS.KNET:
        return this.translate(translations.KNET);
      case PAYMENT_METHODS_ENUMS.APPLE_PAY:
        return this.translate(translations.APPLE_PAY);
      case PAYMENT_METHODS_ENUMS.MADA:
        return this.translate(translations.MADA);
      default:
        return '';
    }
  };

  render() {
    const { lang, store, isGift, invoiceRef, printSize, legalData } = this.props;
    const { order } = this.props;
    const name = [store.titleEn, store.titleAr];
    lang === 'ar' ? moment.locale('ar-kw') : moment.locale('en-gb');
    const direction = lang === 'ar' ? 'rtl' : 'ltr';
    const fromDate = order.timeSlot && order.timeSlot.split(',')[0];
    const toDate = order.timeSlot && order.timeSlot.split(',')[1];
    const theFromDate =
      fromDate &&
      moment(fromDate)
        .tz(store.timeZone)
        .locale(lang === 'ar' ? 'ar' : 'en-gb')
        .format('h:mm A');
    const theToDate =
      toDate &&
      moment(toDate)
        .tz(store.timeZone)
        .locale(lang === 'ar' ? 'ar' : 'en-gb')
        .format('h:mm A');
    const theTimeSlot = theFromDate && theToDate && `${theFromDate} - ${theToDate}`;
    const shouldHidePricing = getShouldHidePricingFlag(store.id);
    return printSize === 'Thermal' ? (
      <ThermalOrderInvoice
        ref={invoiceRef}
        order={order}
        store={store}
        lang={lang}
        isGift={isGift}
        name={name}
        legalData={legalData}
        direction={direction}
        theTimeSlot={theTimeSlot}
        translate={this.translate}
        address={this.Address}
        paidThrough={this.PaidThrough}
        shouldHidePricing={shouldHidePricing}
      />
    ) : (
      <A4OrderInvoice
        ref={invoiceRef}
        order={order}
        store={store}
        lang={lang}
        isGift={isGift}
        name={name}
        legalData={legalData}
        direction={direction}
        theTimeSlot={theTimeSlot}
        translate={this.translate}
        address={this.Address}
        paidThrough={this.PaidThrough}
        shouldHidePricing={shouldHidePricing}
      />
    );
  }
}
