import React, { createContext, useContext, useRef, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import ActionCableProvider, { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { navigate } from '@reach/router';
import camelize from 'camelize';
import moment from 'moment';

import { ORDER_STATUS } from 'constants/order';
import { ORDERS } from 'components/common/orders/Frame/schemas';
import { useSelectedStore, useReplaceParams } from 'hooks/index';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import * as userService from 'services/user';
import * as paths from 'paths.js';
import * as translations from 'constants/translations';
import { ORDER_RING_OPTIONS } from 'constants/orderRingerEnums';
import { beep } from 'constants/beep';

const { REACT_APP_CABLE_URL } = process.env;

export const context = createContext();

export const Provider = ({ children }) => {
  const storeId = useSelectedStore();
  const token = userService.getToken();
  const notifications = useContext(notificationsContext);
  const { branches, settings, setStopRing, stopRing } = useContext(userContext);
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState();
  const [isBulkChange, setIsBulkChange] = useState(false);
  const [orderRingerTone, setOrderRingerTone] = useState();
  const replaceParams = useReplaceParams();
  const client = useApolloClient();
  const ordersVariables = useRef();
  let removeTimeOut;

  const playNotification = () => {
    beep.play().catch(() => {
      return;
    });
  };

  const handleOrderRinger = () => {
    if (localStorage.getItem('ringer_type') === ORDER_RING_OPTIONS.SINGLE) {
      playNotification();
    } else if (localStorage.getItem('ringer_type') === ORDER_RING_OPTIONS.CONTINUOUS) {
      setOrderRingerTone(
        setInterval(() => {
          playNotification();
        }, 0),
      );
      removeTimeOut = setTimeout(() => {
        clearInterval(orderRingerTone);
      }, 300000);
    } else {
      playNotification();
    }
  };

  useEffect(() => {
    if (stopRing === ORDER_RING_OPTIONS.STOP) {
      clearInterval(orderRingerTone);
    }
    return () => {
      clearTimeout(removeTimeOut);
    };
  }, [stopRing]);

  useEffect(() => {
    let timer;
    if (data) {
      timer = setTimeout(() => {
        handleReceived(data);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [data]);

  const handleReceived = data => {
    const order = {
      ...camelize(data.data.attributes),
      id: +data.data.id,
    };

    const dataOrders = client.readQuery({
      query: ORDERS,
      variables: ordersVariables.current,
    });

    // todo, temporary fix dashboard crashing, but its still need to fix.
    if (dataOrders) {
      const statuses = dataOrders?.orders?.statusCount;

      const oldOrder = dataOrders?.orders?.orders.find(({ id }) => id === order.id);
      const oldStatus = oldOrder ? oldOrder.status : '';

      const newStatus = data?.data?.attributes?.status;

      let newOrders = dataOrders?.orders?.orders.filter(order => order.id !== parseInt(data.data.id));
      const stateHistories = [];
      if (data.included.length) {
        data.included.forEach(element => {
          if (element.type === 'state-histories') {
            stateHistories.push({ ...camelize(element.attributes), __typename: 'OrderStateHistory' });
          }
        });
      }

      const formattedOrder = formatOrder(order, stateHistories);

      newOrders = [formattedOrder, ...newOrders];

      client.writeQuery({
        query: ORDERS,
        variables: ordersVariables.current,
        data: {
          orders: {
            ...dataOrders.orders,
            orders: newOrders,
            statusCount:
              oldStatus !== order.status
                ? {
                    ...statuses,
                    [oldStatus]: statuses[oldStatus] - 1,
                    [newStatus]: statuses[newStatus] + 1,
                  }
                : { ...statuses },
          },
        },
      });

      if (
        formattedOrder.partnerErrors &&
        oldOrder?.partnerErrors &&
        oldOrder?.partnerErrors?.length !== formattedOrder.partnerErrors?.length
      ) {
        notifications.show(order.partnerErrors[order.partnerErrors.length - 1], 'error');
      }
      if ((!oldStatus || oldStatus !== order.status) && !isBulkChange) {
        switch (data.data.attributes.status) {
          case ORDER_STATUS.ACCEPTED: {
            if (settings.isAutoAccept) setStopRing(ORDER_RING_OPTIONS.NON_STOP), handleOrderRinger();
            {
              return notifications.show(
                <Text value={translations.ORDER_IS_ACCEPTED(data.data.attributes.number)} />,
                'success',
                () => !isOrdersPage(document.location.pathname) && navigate(replaceParams(paths.acceptedOrders)),
              );
            }
          }
          case ORDER_STATUS.CANCELED:
            return notifications.show(
              <Text value={translations.ORDER_IS_CANCELED(data.data.attributes.number)} />,
              'success',
              () => !isOrdersPage(document.location.pathname) && navigate(replaceParams(paths.canceledOrders)),
            );
          case ORDER_STATUS.DELIVERED:
            return notifications.show(
              <Text value={translations.ORDER_IS_DELIVERED(data.data.attributes.number)} />,
              'success',
              () => !isOrdersPage(document.location.pathname) && navigate(replaceParams(paths.deliveredOrders)),
            );
          case ORDER_STATUS.FULFILLED:
            return notifications.show(
              <Text value={translations.ORDER_IS_FULFILLED(data.data.attributes.number)} />,
              'success',
              () => !isOrdersPage(document.location.pathname) && navigate(replaceParams(paths.fulfilledOrders)),
            );
          case ORDER_STATUS.DISPATCHED:
            return notifications.show(
              <Text value={translations.ORDER_IS_DISPATCHED(data.data.attributes.number)} />,
              'success',
              () => !isOrdersPage(document.location.pathname) && navigate(replaceParams(paths.dispatchedOrders)),
            );
          case ORDER_STATUS.SUBMITTED:
            return setTimeout(() => {
              notifications.show(
                <Text value={translations.ORDER_IS_SUBMITTED(data.data.attributes.number)} />,
                'success',
                () => {
                  !isOrdersPage(document.location.pathname) && navigate(replaceParams(paths.pendingOrders));
                },
                {},
                0,
              ),
                setStopRing(ORDER_RING_OPTIONS.NON_STOP),
                handleOrderRinger();
            }, 8000);
          case ORDER_STATUS.READY:
            return notifications.show(
              <Text value={translations.ORDER_IS_READY(data.data.attributes.number)} />,
              'success',
              () => !isOrdersPage(document.location.pathname) && navigate(replaceParams(paths.readyOrders)),
            );
          default:
            return '';
        }
      }
    }
  };

  return (
    <>
      {token && (
        <ActionCableProvider url={`${REACT_APP_CABLE_URL}/cable?access_token=${token}`}>
          {branches &&
            branches.map(branch => (
              <ActionCableConsumer
                key={branch.id}
                onReceived={setData}
                channel={{
                  channel: `OrdersChannel`,
                  restaurant_id: storeId,
                  branch_id: branch.id,
                }}
              />
            ))}
        </ActionCableProvider>
      )}
      <context.Provider
        value={{
          orders,
          ordersCount: orders.filter(order => !order.isRead).length,
          setOrdersVariables: data => (ordersVariables.current = data),
          ordersVariables: ordersVariables.current,
          checkIsBulkChange: checked => {
            setIsBulkChange(checked);
          },
          markAsRead: id => {
            setOrders(
              orders.map(order =>
                order.id !== id
                  ? order
                  : {
                      ...order,
                      isRead: true,
                    },
              ),
            );
            setOrders(orders.filter(order => order.id !== id));
          },
          clearOrders: () => setOrders([]),
        }}
      >
        {children}
      </context.Provider>
    </>
  );
};

const isOrdersPage = pathname => /^\/[0-9]+\/orders\//.test(pathname);

const formatOrder = (unformattedOrder, stateHistories) => {
  return {
    branchData: {
      id: unformattedOrder.branchData.id,
      lat: parseFloat(unformattedOrder.branchData.lat),
      lng: parseFloat(unformattedOrder.branchData.lng),
      titleAr: unformattedOrder.branchData.titleAr,
      titleEn: unformattedOrder.branchData.titleEn,
      externalId: unformattedOrder.branchData.externalId,
      __typename: 'Branch',
    },
    branchName: unformattedOrder.branchName,
    createdAt: unformattedOrder.createdAt,
    deliveryType: unformattedOrder.deliveryType,
    expectedAt: unformattedOrder.expectedAt,
    id: unformattedOrder.id,
    isScheduled: unformattedOrder.isScheduled,
    firingTime: unformattedOrder.firingTime,
    number: unformattedOrder.number,
    paidThrough: unformattedOrder.paidThrough,
    status: unformattedOrder.status,
    paymentStatus: unformattedOrder.paymentStatus,
    timeSlot: unformattedOrder.timeSlot,
    total: unformattedOrder.total,
    deliveryCourierId: unformattedOrder.deliveryCourierId || null,
    deliveryCourierName: unformattedOrder.deliveryCourierName || null,
    deliveryCourier: unformattedOrder.deliveryCourier && {
      externalOrderIdentifierLink: unformattedOrder.deliveryCourier.externalOrderIdentifierLink || null,
      externalOrderIdentifierType: unformattedOrder.deliveryCourier.externalOrderIdentifierType || null,
      driverAssigned: unformattedOrder.deliveryCourier.driverAssigned || null,
      hasDriverInfo: unformattedOrder.deliveryCourier.hasDriverInfo || null,
      driverName: unformattedOrder.deliveryCourier.driverName || null,
      driverPhoneNumber: unformattedOrder.deliveryCourier.driverPhoneNumber || null,
      trackingLink: unformattedOrder.deliveryCourier.trackingLink || null,
      id: unformattedOrder.deliveryCourier.id || null,
      referenceId: unformattedOrder.deliveryCourier.referenceId || null,
      courierDetails: unformattedOrder?.deliveryCourier?.courierDetails && {
        name: unformattedOrder.deliveryCourier.courierDetails.name,
        id: unformattedOrder.deliveryCourier.courierDetails.id,
      },
      __typename: 'DeliveryCourier',
    },
    deliveryZone: unformattedOrder.deliveryZone
      ? {
          zoneName: unformattedOrder.deliveryZone.zoneName,
        }
      : null,
    userData: {
      address: unformattedOrder.userData.address
        ? {
            area: unformattedOrder.userData.address.area
              ? {
                  titleAr: unformattedOrder.userData.address.area.titleAr,
                  titleEn: unformattedOrder.userData.address.area.titleEn || null,
                  cityTitleEn: unformattedOrder.userData.address.area.cityTitleEn || null,
                  cityTitleAr: unformattedOrder.userData.address.area.cityTitleAr || null,
                  lat: parseFloat(unformattedOrder.userData.address.area.lat) || null,
                  lng: parseFloat(unformattedOrder.userData.address.area.lng) || null,
                  __typename: 'Area',
                }
              : null,

            cityName: unformattedOrder.userData.address.cityName,
            lat: parseFloat(unformattedOrder.userData.address.lat),
            lng: parseFloat(unformattedOrder.userData.address.lng),
            street: unformattedOrder.userData.address.street,
            avenue: unformattedOrder.userData.address.avenue,
            block: unformattedOrder.userData.address.block,
            unitNo: unformattedOrder.userData.address.unitNo,
            unitType: unformattedOrder.userData.address.unitType,
            areaName: unformattedOrder.userData.address.areaName,

            __typename: 'Address',
          }
        : null,
      recipient: unformattedOrder.userData.recipient
        ? {
            name: unformattedOrder.userData.recipient.name,
            phoneNumber: unformattedOrder.userData.recipient.phoneNumber,
            giftNotes: unformattedOrder.userData.recipient.giftNotes,
            __typename: 'Recipient',
          }
        : null,
      name: unformattedOrder.userData.name,
      phoneNumber: unformattedOrder.userData.phoneNumber,
      __typename: 'UserData',
    },
    gift: unformattedOrder.gift,
    inBetweenTransitions: unformattedOrder.inBetweenTransitions,
    partnerErrors: unformattedOrder.partnerErrors,
    stateHistories: stateHistories,
    __typename: 'Order',
  };
};
