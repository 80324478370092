import React from 'react';
import PropTypes from 'prop-types';

import logoPlaceholder from 'assets/logo-placeholder.png';
import OrderInvoiceTitle from './OrderInvoiceTitle';
import OrderInvoiceInfo from './OrderInvoiceInfo';
import OrderInvoiceUserInfo from './OrderInvoiceUserInfo';
import OrderInvoiceItems from './OrderInvoiceItems';
import OrderInvoicePricing from './OrderInvoicePricing';
import OrderInvoiceGift from './OrderInvoiceGift';

class A4OrderInvoice extends React.PureComponent {
  render() {
    const {
      order,
      store,
      lang,
      isGift,
      name,
      direction,
      theTimeSlot,
      translate,
      address,
      paidThrough,
      legalData,
      shouldHidePricing,
    } = this.props;

    return (
      <div className="flex flex-col">
        <OrderInvoiceTitle
          legalData={legalData}
          name={name}
          order={order}
          lang={lang}
          store={store}
          logoPlaceholder={logoPlaceholder}
          translate={translate}
          direction={direction}
          isGift={isGift}
        />
        <OrderInvoiceInfo
          lang={lang}
          direction={direction}
          order={order}
          translate={translate}
          theTimeSlot={theTimeSlot}
          store={store}
        />
        <OrderInvoiceUserInfo
          lang={lang}
          direction={direction}
          order={order}
          translate={translate}
          isGift={isGift}
          addressTranslation={address}
        />
        <OrderInvoiceItems
          lang={lang}
          direction={direction}
          translate={translate}
          isGift={isGift}
          order={order}
          store={store}
          shouldHidePricing={shouldHidePricing}
        />
        {!shouldHidePricing && (
          <OrderInvoicePricing
            lang={lang}
            direction={direction}
            translate={translate}
            isGift={isGift}
            order={order}
            store={store}
            paidThroughMethod={paidThrough}
          />
        )}
        {isGift && <OrderInvoiceGift translate={translate} order={order} direction={direction} lang={lang} />}
      </div>
    );
  }
}

A4OrderInvoice.propTypes = {
  shouldHidePricing: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    discountedAmount: PropTypes.number.isRequired,
    voucherCode: PropTypes.string,
    subtotalAfterVoucher: PropTypes.number,
    deliveryType: PropTypes.string,
    deliveryFee: PropTypes.number,
    tax: PropTypes.number,
    taxPercentage: PropTypes.number,
    taxInclusive: PropTypes.bool,
    total: PropTypes.number.isRequired,
    paidThrough: PropTypes.string,
    voucherAmount: PropTypes.number,
  }),
  store: PropTypes.shape({ timeZone: PropTypes.string, currency: PropTypes.number }),
  lang: PropTypes.string.isRequired,
  isGift: PropTypes.bool,
  name: PropTypes.string,
  direction: PropTypes.string.isRequired,
  theTimeSlot: PropTypes.string,
  translate: PropTypes.func,
  address: PropTypes.shape({
    area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
  }),
  paidThrough: PropTypes.func,
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
};
export default A4OrderInvoice;
