import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { QTY, ITEM, PRICE, SUBTOTAL } from 'constants/translations';
import { Text } from 'components/service';
import OrderDetailsCard from '../../Table/Order/OrderDetails/OrderDetailsCard';

const ThermalInvoiceItems = ({ lang, translate, direction, isGift, store, order, shouldHidePricing }) => {
  const { currency } = store || {};
  const { orderItems, subtotal } = order || {};
  return (
    <div>
      <div className={cx('flex border-dashed border-gray-300 border-t w-full', lang === 'ar' && 'flex-row-reverse')}>
        <div className={cx('flex justify-between w-full', lang === 'ar' && 'flex-row-reverse')}>
          <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
            <span className="w-9 inline-block text-xs font-bold" style={{ direction }}>
              {translate(QTY)}
            </span>
            <span className={cx('text-xs font-bold', lang === 'ar' ? 'mr-3' : 'ml-3')}>{translate(ITEM)}</span>
          </div>
          <div>{!isGift && !shouldHidePricing && <span className="text-xs font-bold">{translate(PRICE)}</span>}</div>
        </div>
      </div>
      <div
        className={cx('flex flex-col p-3 border-dashed border-gray-300 border-t ', lang === 'ar' && 'flex-row-reverse')}
      >
        {orderItems.map(item => {
          const { quantity, menuItem, variant, properties, totalPrice, notes, id: menuItemId } = item || {};
          const variantTitles = { titleAr: variant.titleAr, titleEn: variant.titleEn };
          return (
            <div key={menuItemId} className={cx('flex flex-col w-full', lang === 'ar' && 'items-end')}>
              <span className={cx('flex text-sm font-bold', lang === 'ar' && 'flex-row-reverse')} style={{ direction }}>
                {quantity} X
              </span>
              <div className="flex flex-col w-full">
                <div className={cx('flex justify-between w-full mb-3', lang === 'ar' && 'flex-row-reverse')}>
                  <div className="flex flex-col">
                    <span className="text-sm font-bold" style={{ direction }}>
                      {translate(menuItem)}
                      {variant && (
                        <>
                          <span> - </span>
                          <Text selectedLang={lang} value={variantTitles} className="inline" />
                          {variant.barCode && (
                            <span style={{ direction }} className={cx(lang === 'ar' ? 'mr-1' : 'ml-1')}>
                              ({variant.barCode})
                            </span>
                          )}
                        </>
                      )}
                    </span>
                    {notes && notes.replace(/\s/g, '').length !== 0 && (
                      <div
                        key={menuItemId}
                        className={cx(
                          'flex justify-between w-full mt-2 text-xs font-bold',
                          lang === 'ar' && 'flex-row-reverse',
                        )}
                      >
                        <span style={{ direction }}>{notes}</span>
                      </div>
                    )}
                  </div>
                  {!isGift && !shouldHidePricing && (
                    <div className="text-xs font-bold" style={{ direction }}>
                      <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                      {totalPrice.toFixed(currency.decimals)}
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  {properties.length > 0 &&
                    properties.map(property => {
                      const propertyTitles = { titleAr: property.titleAr, titleEn: property.titleEn };
                      return property.propertyValues.map(propertyValue => {
                        const propertyValueNames = { titleAr: propertyValue.titleAr, titleEn: propertyValue.titleEn };
                        return (
                          <OrderDetailsCard
                            title={propertyTitles}
                            quantity={propertyValue.quantity * quantity}
                            itemQuantity={quantity}
                            optionName={propertyValueNames}
                            price={propertyValue.price}
                            currency={currency}
                            numberOfEach={propertyValue.quantity}
                            lang={lang}
                            direction={direction}
                            isGift={isGift}
                            isThermalInvoice
                            shouldHidePricing={shouldHidePricing}
                          />
                        );
                      });
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {!isGift && !shouldHidePricing && (
        <div
          className={cx(
            'flex justify-between pb-1 pt-2 border-dashed border-gray-300 border-t ',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <span className="text-sm">{translate(SUBTOTAL)}</span>
          <div className="text-sm" style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
            {subtotal.toFixed(currency.decimals)}
          </div>
        </div>
      )}
    </div>
  );
};

ThermalInvoiceItems.propTypes = {
  lang: PropTypes.string,
  translate: PropTypes.func,
  order: PropTypes.shape({
    orderItems: PropTypes.shape({
      quantity: PropTypes.number,
      variant: PropTypes.shape({
        barCode: PropTypes.string,
        discountEnabled: PropTypes.bool.isRequired,
        discountedPrice: PropTypes.number,
        externalId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        prepTime: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        propertySections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
        sku: PropTypes.string,
        titleAr: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
      }),
      totalPrice: PropTypes.number.isRequired,
      propertyValues: PropTypes.shape({ quantity: PropTypes.number, price: PropTypes.number, id: PropTypes.string }),
      notes: PropTypes.string,
      menuItem: PropTypes.shape({ titleEn: PropTypes.string.isRequired, titleAr: PropTypes.string.isRequired }),
      id: PropTypes.string.isRequired,
    }),
    subtotal: PropTypes.number,
  }),
  isGift: PropTypes.bool,
  store: PropTypes.shape({ currency: PropTypes.string }),
  direction: PropTypes.string,
  shouldHidePricing: PropTypes.bool.isRequired,
};
export default ThermalInvoiceItems;
