import React, { useContext } from 'react';
import cx from 'classnames';
import SpinnerAlt from '../SpinnerAlt';
import { context as localeContext } from 'context/locale';

export default ({
  type = 'submit',
  kind = 'action',
  size = 'base',
  iconSize = 'sm',
  weight = 'semibold',
  isSpinning = false,
  isLink = false,
  isRounded = false,
  isPillShape = false,
  full,
  hFull,
  children,
  icon,
  textColor,
  borderColor,
  isMenu = false,
  iconColor,
  isChevron,
  iconClassNames,
  className,
  ...props
}) => {
  const Component = isLink ? 'a' : 'button';
  const customProps = isLink ? { rel: 'noopener noreferrer', target: '_blank' } : { type };
  const disabled = props.disabled || isSpinning;
  const { lang } = useContext(localeContext);
  return (
    <Component
      {...props}
      {...customProps}
      disabled={disabled}
      className={cx(
        `whitespace-nowrap relative group flex items-center justify-center leading-none font-${weight} border focus:outline-none ` +
          (isMenu ? ' px-0' : sizes[size]),
        disabled && 'disabled:opacity-75 disabled:cursor-not-allowed disabled:pointer-events-none',
        styles[kind],
        textColor,
        isLink && 'inline-flex',
        full && 'w-full',
        hFull && '!h-full',
        isMenu && ' border-none',
        isChevron && 'flex-row-reverse',
        borderColor,
        isRounded ? (isPillShape ? 'rounded-full' : 'rounded') : 'rounded-none',
        className,
      )}
    >
      {isSpinning ? (
        <div className="mx-2">
          <SpinnerAlt
            color={
              kind === 'tertiary' ||
              kind === 'regular' ||
              kind === 'action' ||
              kind === 'transparent' ||
              kind === 'secondary' ||
              kind === 'outline' ||
              kind === 'secondaryError'
                ? 'black'
                : 'white'
            }
          />
        </div>
      ) : (
        icon && (
          <i
            className={cx(
              'material-icons',
              `text-${iconSize}`,
              children && 'mx-2',
              iconColor,
              isChevron && (lang === 'ar' ? 'mr-0' : 'ml-0'),
              iconClassNames,
            )}
          >
            {icon}
          </i>
        )
      )}
      {children}
    </Component>
  );
};

const sizes = {
  xs: 'h-10 px-2 text-sm',
  sm: 'h-8 px-3 text-xs',
  smIcon: 'h-8 px-1',
  trash: 'p-2',
  option: 'h-10 px-3 text-sm',
  baseIcon: 'h-10 px-3',
  baseGroup: 'h-10 px-3 md:px-6 text-sm',
  base: 'h-10 px-6 text-sm',
  full: 'h-10 px-6 text-sm w-full text-center',
  md: 'h-10 px-3 md:px-4 py-3 text-sm',
  close: 'py-0 px-0 h-3',
  link: 'h-10 text-sm',
  tertiaryLink: 'h-8 text-sm',
  popup: 'h-10 w-full text-xs',
};

const styles = {
  primary:
    'bg-primary-base border-transparent text-white hover:bg-primary-500 focus:bg-primary-base focus:border-primary-600 active:bg-primary-700 disabled:bg-zyda-grey-5 disabled:text-zyda-grey-20',
  secondary:
    'bg-white border-gray-300 text-zyda-black-100 focus:border-primary-base hover:border-primary-base hover:text-primary-base active:bg-primary-100 disabled:text-zyda-grey-20 disabled:border-zyda-grey-40',
  secondaryError:
    'bg-transparent border-gray-300 text-red-600 focus:bg-primary-50 hover:border-zyda-red-500 active:bg-primary-100 disabled:text-zyda-grey-20 disabled:border-zyda-grey-40',
  tertiary:
    'bg-white border-gray-300 hover:border-transparent hover:bg-gray-200 focus:border-gray-300 focus:text-primary-base active:bg-zyda-grey-100 disabled:border-gray-300 disabled:text-zyda-grey-20',
  outline: 'bg-white border-primary-base text-primary-base disabled:opacity-60',
  quartary:
    'bg-transparent border-gray-300 text-zyda-black-100 focus:bg-primary-50 hover:border-black active:bg-primary-100 disabled:text-zyda-grey-20 disabled:border-zyda-grey-40',
  transparent: 'bg-white text-primary-base text-xs border-none',
  link: 'bg-transparent text-primary-base text-xs border-none',
  tertiaryLink: 'bg-transparent text-black text-xs border-none',
  borderlessButton: 'bg-white text-zyda-black-100 border-transparent hover:border-transparent',
};
