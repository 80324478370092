import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ORDER_STATUS } from 'constants/order';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useAssignScheduledOrderNow, useFetchVerdDrivers } from 'service/hooks';
import { useSelectedStore } from 'hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import AddRiderModal from './AddRiderModal';
import useHandleAssignUnAssignDriverToOrder from './handleAssignDrivertoOrder';
import AssignDeliveryModal from './AssignDeliveryModal';
import useHandleAssignUnAssignCourierToOrder from './handleAssignCourierToOrder';
import useGetCourierInfoWithLogo from './useGetCourierInfoWithLogo';

export const DeliveryActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  deliveryType,
  open,
  close,
  closeable,
  updatedCourierId,
}) => {
  const { status: orderStatus, branchData } = order || {};
  const { lang, translate } = useContext(localeContext);
  const { settings, selectedStore } = useContext(userContext);
  const { restaurantCourierSetting } = selectedStore || {};
  const { user } = useContext(userContext);
  const assignScheduledOrderNow = useAssignScheduledOrderNow();
  const selectedStoreId = useSelectedStore();
  const { data: activeRiders, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId: parseInt(branchData.id),
  });
  const [isDeliverySlideOverOpen, setIsDeliverySlideOverOpen] = useState(false);
  const [nextOrderStatus, setNextOrderStatus] = useState('');

  const isVerdEnabled = settings?.enableVerd;
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);

  const [shouldShowRiderModal, setShouldShowRiderModal] = useState(verd?.showRiderModal || false);

  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const deliveryOrderStatus = order?.deliveryStatus;
  const isOrderDeliveryCourierNotVerd = !order?.deliveryCourier?.isInternalDelivery;
  const isScheduledOrder = order?.isScheduled;
  const isInternalDelivery = order?.deliveryCourier?.isInternalDelivery;
  const isPendingScheduledOrder = isScheduledOrder && deliveryOrderStatus === ORDER_RIDER_STATUS.PENDING;
  const orderDeliveryCourierId = order?.deliveryCourier?.courierId;

  const { handleAssignCourierToOrder } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const { handleAssignDriverToOrder } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const {
    restaurantCouriersWithLogos,
    isCouriersEstimationsLoading,
    courierEstimationsError,
  } = useGetCourierInfoWithLogo(order);

  const assignedCourier = restaurantCouriersWithLogos?.find(
    courier => Number(courier.courierId) === orderDeliveryCourierId,
  );
  const hasCourierInfo = orderDeliveryCourierId && isOrderDeliveryCourierNotVerd;
  const hasRiders = riders?.length !== 0;

  const orderDeliveryPhoneNumber = order?.deliveryCourier?.driverPhoneNumber;
  const assignedDriver = riders?.find(driver => driver.phone === orderDeliveryPhoneNumber);
  const isOrderAssignedToCourierOrRider = assignedDriver?.id || assignedCourier?.courierId;
  const quickAssignCourierId = order?.deliveryCourierId;

  const AddRiderModalBody = () => (
    <AddRiderModal
      close={close}
      closeable={closeable}
      mutateFetchVerdDrivers={mutateFetchVerdDrivers}
      sendChangeStatus={sendChangeStatus}
      handleAssignDriverToOrder={handleAssignDriverToOrder}
      setShouldShowRiderModal={setShouldShowRiderModal}
    />
  );

  const shouldOpenAddRiderModal = !(hasRiders || hasCourierInfo) && shouldShowRiderModal;
  const shouldOpenAssignDeliveryModal =
    !(assignedDriver || assignedCourier) &&
    (restaurantCouriersWithLogos?.length > 0 || (isVerdEnabled && riders?.length !== 0));

  const ShowNextStatus = () => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse')}>
            <Button
              rounded
              isLoading={isUpdatingStatus}
              isDisabled={shouldDisableActionBtns}
              onClick={() => {
                if (shouldOpenAddRiderModal)
                  open({
                    body: AddRiderModalBody(),
                  });
                else if (shouldOpenAssignDeliveryModal) {
                  setIsDeliverySlideOverOpen(true);
                  setNextOrderStatus(ORDER_STATUS.ACCEPTED);
                } else sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
              }}
              text={translate(translations.ACCEPT_ORDER)}
              data-testid="accept-order-button"
            />
          </div>
        );
      case ORDER_STATUS.ACCEPTED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              if (isVerdEnabled && !isOrderAssignedToCourierOrRider) {
                setIsDeliverySlideOverOpen(true);
                setNextOrderStatus(ORDER_STATUS.READY);
              } else sendChangeStatus({ status: ORDER_STATUS.READY, sendCourierId: false });
            }}
            size="medium"
            text={
              isVerdEnabled && !isOrderAssignedToCourierOrRider
                ? translate(translations.ASSIGN_DELIVERY)
                : translate(translations.READY_ORDER)
            }
            data-testid="ready-order-button"
          />
        );
      case ORDER_STATUS.READY:
        if (deliveryType === DELIVERY_BUSY_MODE.PICKUP || deliveryType === DELIVERY_BUSY_MODE.BEACH) {
          return (
            <Button
              rounded
              isLoading={isUpdatingStatus}
              isDisabled={shouldDisableActionBtns}
              onClick={() => {
                sendChangeStatus({ status: ORDER_STATUS.FULFILLED, sendCourierId: false });
              }}
              size="medium"
              text={translate(translations.FULFILL_ORDER)}
              data-testid="fulfill-order-button"
            />
          );
        }
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DISPATCHED, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.DISPATCH_ORDER)}
            data-testid="dispatch-order-button"
          />
        );

      case ORDER_STATUS.DISPATCHED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DELIVERED, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.DELIVER_ORDER)}
            data-testid="deliver-order-button"
          />
        );
      case ORDER_STATUS.DELIVERED:
      default:
        return null;
    }
  };

  const AssignScheduledNow = () => (
    <Button
      rounded
      isLoading={isUpdatingStatus}
      isDisabled={shouldDisableActionBtns}
      onClick={() => {
        assignScheduledOrderNow({
          orderNumber: order.number,
          userId: user?.id,
        });
      }}
      size="medium"
      variant="tertiary"
      text={<Text value={translations.DELIVER_NOW} />}
      data-testid="assign-now-button"
    />
  );

  return isPendingScheduledOrder && !isInternalDelivery ? (
    <div className="flex flex-row justify-between">
      <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4')}>
        <AssignScheduledNow />
      </div>
      <ShowNextStatus />
    </div>
  ) : (
    <div className="flex justify-end">
      <ShowNextStatus />
      <Slideover
        isOpen={isDeliverySlideOverOpen}
        body={
          <AssignDeliveryModal
            order={order}
            mutateFetchVerdDrivers={mutateFetchVerdDrivers}
            close={() => setIsDeliverySlideOverOpen(false)}
            handleAssignDriverToOrder={handleAssignDriverToOrder}
            handleAssignCourierToOrder={handleAssignCourierToOrder}
            assigned={isOrderAssignedToCourierOrRider}
            restaurantCouriersWithLogos={restaurantCouriersWithLogos}
            riders={riders}
            quickAssignCourierId={quickAssignCourierId}
            sendChangeStatus={sendChangeStatus}
            nextOrderStatus={nextOrderStatus}
            updatedCourierId={updatedCourierId}
            isCouriersEstimationsLoading={isCouriersEstimationsLoading && !courierEstimationsError}
          />
        }
      />
    </div>
  );
};

DeliveryActionButtons.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    deliveryStatus: PropTypes.string,
    isScheduled: PropTypes.bool,
    deliveryCourierId: PropTypes.number,
    number: PropTypes.string,
    deliveryCourier: PropTypes.shape({
      isInternalDelivery: PropTypes.bool,
      courierId: PropTypes.number,
      driverPhoneNumber: PropTypes.string,
    }),
  }),
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
  updatedCourierId: PropTypes.number,
};
export default DeliveryActionButtons;
