import React, { useContext, useState } from 'react';
import { Button, Typography, useTheme, TextInput, Divider } from '@zydalabs/zac-react';
import { SettingsIcon, InfoIcon, XIcon } from '@zydalabs/zac-icons-react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'style.css';

import { ORDER_STATUS } from 'constants/order';
import { Field } from 'components/service';
import { DropDown } from 'components/kit';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import * as paths from 'paths.js';
import Popup from 'components/kit/Popup';
import OrderDeliveryConfirmation from 'components/common/orders/OrderDeliveryConfirmation';
import { PhoneInput, RadioList, CheckboxAlt } from 'components/form/elements';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { replaceParams } from 'utils/index';
import { useSelectedStore } from 'hooks';
import { RIDER_JOB_TYPES } from 'constants/riderJobTypes';
import { RIDER_LANGUAGES } from 'constants/riderLanguages';
import riderValidationSchema from 'components/common/verd/RiderForm/validationSchema';
import { calculateNewExpectedAt } from 'components/common/orders/Table/Order/helpers';
import capitalizeFirstLetter from 'common/utils/capitalizeFirstLetter';
import StoreRider from './StoreRider';
import StoreCourier from './StoreCourier';
import useOnSubmitRider from './useOnSubmitRider';

const AssignDeliveryModal = ({
  order,
  mutateFetchVerdDrivers,
  close,
  handleAssignDriverToOrder,
  handleAssignCourierToOrder,
  assigned,
  restaurantCouriersWithLogos,
  riders,
  quickAssignCourierId,
  sendChangeStatus,
  nextOrderStatus,
  updatedCourierId,
  isLoading,
  isBulkAssign,
  isCouriersEstimationsLoading,
}) => {
  const { translate, lang, direction } = useContext(localeContext);
  const { settings, selectedStore, branches } = useContext(userContext);
  const { colors } = useTheme();
  const storeId = useSelectedStore();
  const [searchInput, setSearchInput] = useState('');
  const [selected, setSelected] = useState(assigned);
  const [isAddRiderLoading, setIsAddingRiderLoading] = useState(false);
  const [searchBy, setSearchBy] = useState('phone');
  const [isDeliveryConfirmationPopupOpen, setIsDeliveryConfirmationPopupOpen] = useState(false);

  const isAddingRider = selected === 'add_rider';
  const isArabic = lang === 'ar';
  const changeStatus = () => !!sendChangeStatus && sendChangeStatus({ status: nextOrderStatus, sendCourierId: false });

  const { restaurantCourierSetting, currency, timeZone } = selectedStore || {};
  const { deliveryStatus: deliveryOrderStatus, expectedAt, deliveryCourier } = order || {};
  const { isInternalDelivery } = deliveryCourier || {};

  const isOrderDeliveryCourierNotVerd = !isInternalDelivery;
  const branchesIdList = branches.map(branch => branch.id);
  const changeFrom = moment(expectedAt)
    .tz(timeZone)
    .locale(isArabic ? 'ar' : 'en-gb')
    .format('DD/MM hh:mm A');

  const changeTo =
    !isBulkAssign &&
    calculateNewExpectedAt(order)
      .tz(timeZone)
      .locale(isArabic ? 'ar' : 'en-gb')
      .format('DD/MM hh:mm A');

  const isNewExpectedAtMoreThanOldExpectedAt = changeTo > changeFrom;
  const isCourierNotDeclinedTheRequest =
    (!!assigned && isOrderDeliveryCourierNotVerd && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED) ||
    isNewExpectedAtMoreThanOldExpectedAt;

  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const isVerdRiderSelected = !!selected?.phone;
  const isVerdEnabled = settings?.enableVerd;

  const networkFeesText = restaurantCouriersWithLogos?.map(
    courier =>
      courier.inNetwork &&
      `${isArabic ? courier.courierDetails?.displayNameAr : courier.courierDetails?.displayNameEn}: ${translate(
        currency,
      )} ${parseFloat(courier.networkFees)?.toFixed(currency.decimals)}`,
  );
  if (verd?.networkFees)
    networkFeesText?.push(
      `${capitalizeFirstLetter(verd?.courierDetails?.name)}: ${translate(currency)} ${parseFloat(
        verd?.networkFees,
      )?.toFixed(currency.decimals)}`,
    );

  const assignDelivery = () =>
    isVerdRiderSelected ? handleAssignDriverToOrder(selected) : handleAssignCourierToOrder(selected);

  const handleAssignButton = () =>
    !isBulkAssign && isCourierNotDeclinedTheRequest ? setIsDeliveryConfirmationPopupOpen(true) : assignDelivery();

  const onAssignOrClose = () => {
    close();
    if (nextOrderStatus === ORDER_STATUS.ACCEPTED) {
      changeStatus();
    } else if (!(assigned || isVerdEnabled)) changeStatus();
  };

  const routeToVerdPage = () => {
    onAssignOrClose();

    navigate(
      replaceParams(paths.verd, {
        storeId,
      }),
    );
  };

  const ridersListComponent = riders
    ?.filter(rider => !rider.isHidden)
    ?.filter(rider => {
      if (searchInput === '') {
        return rider;
      }
      const searchByPhone = rider.phone?.toLowerCase()?.includes(searchInput);
      const searchByName = rider.name?.toLowerCase()?.includes(searchInput);

      return searchBy === 'name' ? searchByName : searchByPhone;
    })
    ?.map(rider => (
      <StoreRider key={rider.id} rider={rider} verd={verd} selected={selected} setSelected={setSelected} />
    ));

  const couriersListComponent = restaurantCouriersWithLogos
    ?.filter(courier => !courier?.inNetwork)
    ?.map(courier => (
      <StoreCourier
        key={Number(courier.courierId)}
        courier={courier}
        selected={selected}
        setSelected={setSelected}
        quickAssignCourierId={quickAssignCourierId}
        isCouriersEstimationsLoading={isCouriersEstimationsLoading}
      />
    ));

  const verdNetworkListComponent = restaurantCouriersWithLogos
    ?.filter(courier => !!courier?.inNetwork)
    ?.map(courier => (
      <StoreCourier
        key={Number(courier.courierId)}
        courier={courier}
        selected={selected}
        setSelected={setSelected}
        quickAssignCourierId={quickAssignCourierId}
        isCouriersEstimationsLoading={isCouriersEstimationsLoading}
      />
    ));

  const handleSearch = e => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const { onSubmit } = useOnSubmitRider({
    mutateFetchVerdDrivers,
    handleAssignDriverToOrder,
    changeStatus: () => sendChangeStatus({ status: nextOrderStatus, sendCourierId: false }),
    hasDontShowAgainButton: !!sendChangeStatus,
    close: () => !isDeliveryConfirmationPopupOpen && onAssignOrClose(),
    setIsAddingRiderLoading,
    onSubmitMessage: <Typography>{translate(translations.ADD_AND_ASSIGN_RIDER_SUCCESS)}</Typography>,
    isBulkAssign,
  });

  const dividerComponent = (
    <div className="relative">
      <div className="zac-divider">
        <Divider />
      </div>
      <div className="absolute top-1/2 right-1/2 -translate-y-2/4 h-fit px-1 bg-white">
        <Typography variant="body14" color={colors.gray[400]}>
          {translate(translations.OR)}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          phone: '',
          jobType: RIDER_JOB_TYPES.FREELANCE,
          language: RIDER_LANGUAGES.ENGLISH,
          branches: branchesIdList,
          isHidden: true,
        }}
        validationSchema={riderValidationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form className="h-full" style={{ direction }}>
            <div className="min-h-full flex flex-col">
              <div className="sticky top-0 bg-white w-full flex justify-between items-center border-b px-6 pt-4 pb-4 z-10">
                <div className="flex items-center gap-1">
                  <div className="flex items-center justify-center cursor-pointer">
                    <XIcon width="30px" color="black" onClick={() => onAssignOrClose()} />
                  </div>

                  <Typography variant="heading20">{translate(translations.ASSIGN_DELIVERY)}</Typography>
                </div>
                <Button
                  variant="tertiary"
                  text={translate(translations.MANAGE)}
                  rounded
                  size="small"
                  onClick={routeToVerdPage}
                  startIcon={<SettingsIcon width="20px" color={colors.blue.primary} />}
                />
              </div>
              <div className="flex flex-col px-6">
                {!isBulkAssign && (
                  <>
                    {verdNetworkListComponent.length !== 0 && (
                      <>
                        <div className="mb-5">
                          <Typography variant="heading16" mt={16} mb={16}>
                            {translate(translations.ACTIVE_VERD_NETWORK(verdNetworkListComponent.length))}
                          </Typography>
                          {verdNetworkListComponent}
                        </div>
                        {(couriersListComponent.length !== 0 || isVerdEnabled) && dividerComponent}
                      </>
                    )}
                    {couriersListComponent.length !== 0 && (
                      <>
                        <div className="mb-5">
                          <Typography variant="heading16" mt={28} mb={16}>
                            {translate(translations.ACTIVE_COURIERS(couriersListComponent.length))}
                          </Typography>
                          {couriersListComponent}
                        </div>
                        {isVerdEnabled && dividerComponent}
                      </>
                    )}
                  </>
                )}
                {isVerdEnabled && (
                  <>
                    <Typography variant="heading16" mt={28} mb={16}>
                      {translate(translations.ASSIGN_NOTIFY_WHATSAPP)}
                    </Typography>
                    <div className="flex gap-1">
                      <DropDown
                        float={lang === 'ar' ? 'right' : 'left'}
                        optionSelected={searchBy}
                        onSelect={option => {
                          if (option !== searchBy) {
                            setSearchInput('');
                            setFieldValue('name', '');
                            setFieldValue('phone', null);
                            if (ridersListComponent.length !== 0) setSelected(false);
                          }
                          setSearchBy(option);
                        }}
                        data={[
                          { id: 'phone', title: isArabic ? translations.PHONE[1] : translations.PHONE[0] },
                          { id: 'name', title: isArabic ? translations.NAME[1] : translations.NAME[0] },
                        ]}
                        icon="keyboard_arrow_down"
                        position={lang === 'ar' ? 'right' : 'left'}
                        containerStyle={{
                          backgroundColor: '#f3f4f6',
                          height: '50px',
                        }}
                        noBorder
                        noMargin
                      />
                      {searchBy === 'name' ? (
                        <div className="text-input" aria-hidden="true">
                          <Field
                            type="text"
                            name="name"
                            component={TextInput}
                            placeholder={translate(translations.SEARCH_RIDERS)}
                            size="small"
                            value={searchInput}
                            onChange={e => {
                              handleSearch(e);
                              setFieldValue(e.target.name, e.target.value);
                              if (ridersListComponent.length === 0) setSelected('add_rider');
                              else setSelected(false);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="w-full" aria-hidden="true">
                          <Field
                            name="phone"
                            component={PhoneInput}
                            defaultCountry={selectedStore.countryCode?.toLowerCase()}
                            size="large"
                            height="h-12"
                            value={searchInput}
                            onChange={e => {
                              handleSearch(e);
                              setFieldValue(e.target.name, e.target.value);
                              if (ridersListComponent.length === 0) setSelected('add_rider');
                              else setSelected(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {ridersListComponent.length !== 0 && (
                      <>
                        <div className="w-full flex justify-between items-center mt-6 mb-4">
                          <Typography color={colors.gray[700]}>
                            {translate(translations.ACTIVE_RIDERS(ridersListComponent.length))}
                          </Typography>
                          <Typography color={colors.gray[700]}>{translate(translations.LAST_ASSIGNMENT)}</Typography>
                        </div>
                        {ridersListComponent}
                      </>
                    )}
                    {ridersListComponent.length === 0 && (
                      <div className="flex gap-4 w-full flex-col mt-4">
                        {searchBy === 'name' ? (
                          <div
                            className="text-input h-full"
                            onClick={() => setSelected('add_rider')}
                            aria-hidden="true"
                          >
                            <Field
                              name="phone"
                              component={PhoneInput}
                              defaultCountry={selectedStore.countryCode?.toLowerCase()}
                              size="large"
                              height="h-12"
                            />
                            <div className="px-4 py-1 mt-2 bg-yellow-50 border border-yellow-300">
                              <Typography variant="body14">
                                {translate(translations.CLIENT_WILL_BE_NOTIFIED)}
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <div className="text-input" onClick={() => setSelected('add_rider')} aria-hidden="true">
                            <Field
                              type="text"
                              name="name"
                              label={translate(translations.NAME)}
                              component={TextInput}
                              placeholder={translate(translations.RIDER_NAME)}
                              size="small"
                            />
                          </div>
                        )}
                        <Field
                          name="jobType"
                          items={[
                            {
                              value: RIDER_JOB_TYPES.FREELANCE,
                              title: <Typography>{translate(translations.FREELANCER)}</Typography>,
                            },
                            {
                              value: RIDER_JOB_TYPES.FULL_TIME,
                              title: <Typography>{translate(translations.FULLTIME)}</Typography>,
                              checked: true,
                            },
                            {
                              value: RIDER_JOB_TYPES.PART_TIME,
                              title: <Typography>{translate(translations.PART_TIME)}</Typography>,
                              checked: true,
                            },
                          ]}
                          component={RadioList}
                          direction="row"
                          onChange={e => {
                            setFieldValue('jobType', e.target.value);
                            if (e.target.value === RIDER_JOB_TYPES.FREELANCE) setFieldValue('isHidden', true);
                            else setFieldValue('isHidden', false);
                          }}
                          elementStyle={{
                            direction: lang === 'ar' ? 'rtl' : 'unset',
                          }}
                        />
                        {values?.jobType === RIDER_JOB_TYPES.FREELANCE && (
                          <div className="flex mb-2">
                            <Field
                              name="isHidden"
                              value={!values?.isHidden}
                              component={CheckboxAlt}
                              title={
                                <Typography cursor="pointer" variant="body14">
                                  {translate(translations.ADD_TO_DRIVERS_LIST)}
                                </Typography>
                              }
                              onChange={e => setFieldValue('isHidden', !e.target.value)}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="sticky bottom-0 bg-white flex flex-col justify-center items-center py-4 px-6 border-t gap-4 mt-auto">
                {isAddingRider ? (
                  <Button
                    type="submit"
                    isLoading={isAddRiderLoading}
                    text={translate(translations.ADD_AND_ASSIGN_NOW)}
                    rounded
                    isFluid
                    isDisabled={!selected}
                    size="large"
                    onClick={() => ({})}
                  />
                ) : (
                  <Button
                    isLoading={isAddRiderLoading || isLoading}
                    text={translate(translations.ASSIGN_NOW)}
                    rounded
                    isFluid
                    isDisabled={!selected || isLoading}
                    size="large"
                    onClick={() => {
                      handleAssignButton();
                      if (!isBulkAssign && !isCourierNotDeclinedTheRequest) onAssignOrClose();
                    }}
                  />
                )}

                <div className="flex gap-1 items-center">
                  <Typography variant="body12" color={colors.gray[400]}>
                    {translate(translations.PROCESS_FEES_APPLY)}
                  </Typography>
                  <InfoIcon width="18px" color="#c1c1c1" data-tooltip-id="tooltip" />
                  <ReactTooltip place="top" id="tooltip" style={{ maxWidth: 'fit-content' }}>
                    <ul className="list-disc" dir={lang === 'en' ? 'ltr' : 'rtl'}>
                      {networkFeesText?.map(
                        singleInformationLine =>
                          !!singleInformationLine && (
                            <li>
                              <Typography variant="body12" color="white">
                                {singleInformationLine}
                              </Typography>
                            </li>
                          ),
                      )}
                    </ul>
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {isDeliveryConfirmationPopupOpen && (
        <Popup
          heading={
            isOrderDeliveryCourierNotVerd ? translations.CONFIRM_CANCELLATION : translations.NEW_ETA_CONFIRMATION
          }
          close={setIsDeliveryConfirmationPopupOpen}
          size="max-w-xl"
        >
          <OrderDeliveryConfirmation
            order={order}
            assignDriverToOrder={isVerdRiderSelected ? handleAssignDriverToOrder : handleAssignCourierToOrder}
            updatedCourierId={updatedCourierId}
            sendChangeStatus={sendChangeStatus}
            onConfirm={() => onAssignOrClose()}
            onCancel={() => setIsDeliveryConfirmationPopupOpen()}
            assignee={selected}
          />
        </Popup>
      )}
    </>
  );
};

export default AssignDeliveryModal;

AssignDeliveryModal.propTypes = {
  close: PropTypes.func,
  handleAssignDriverToOrder: PropTypes.func,
  handleAssignCourierToOrder: PropTypes.func,
  sendChangeStatus: PropTypes.func,
  quickAssignCourierId: PropTypes.number,
};
