import React, { useState, useContext } from 'react';
import cx from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { Button, useTheme } from '@zydalabs/zac-react';
import { DownloadIcon } from '@zydalabs/zac-icons-react';

import { Modal } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { Text, Field } from 'components/service';
import * as translations from 'constants/translations';
import { RadioList, CheckboxAlt } from 'components/form/elements';
import { Footer } from 'components/form/generic';
import { ORDER_STATUS } from 'constants/order';
import { exportOrdersReport } from '../../../../rest';

const ExportOrders = ({ storeId, ordersVariables, canExportWFilters }) => {
  const { lang, translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const [isExporting, setIsExporting] = useState(false);
  const { colors } = useTheme();

  const allOrderStatuses = [
    {
      id: ORDER_STATUS.ALL,
      title: <Text value={translations.EXPORT_ALL_ORDERS} />,
    },
    {
      id: ORDER_STATUS.SUBMITTED,
      title: <Text value={translations.EXPORT_PENDING_ORDERS} />,
    },
    {
      id: ORDER_STATUS.ACCEPTED,
      title: <Text value={translations.EXPORT_ACCEPTED_ORDERS} />,
    },
    {
      id: ORDER_STATUS.READY,
      title: <Text value={translations.EXPORT_READY_ORDERS} />,
    },
    {
      id: ORDER_STATUS.DISPATCHED,
      title: <Text value={translations.EXPORT_DISPATCHED_ORDERS} />,
    },
    {
      id: ORDER_STATUS.FULFILLED,
      title: <Text value={translations.EXPORT_COMPLETED_ORDERS} />,
    },
    {
      id: ORDER_STATUS.CANCELED,
      title: <Text value={translations.EXPORT_CANCELED_ORDERS} />,
    },
    {
      id: ORDER_STATUS.WAITING_FOR_PAYMENT,
      title: <Text value={translations.EXPORT_WAITING_FOR_PAYMENT_ORDERS} />,
    },
    {
      id: ORDER_STATUS.PAYMENT_FAILED,
      title: <Text value={translations.EXPORT_PAYMENT_FAILED_ORDERS} />,
    },
  ];

  const exportOrders = async filters => {
    setIsExporting(true);
    exportOrdersReport(storeId, filters)
      .then(() => {
        notifications.show(<Text value={translations.EXPORT_MESSAGE} />);
        setIsExporting(false);
      })
      .catch(() => {
        notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      });
  };

  return (
    <Modal>
      {({ open, close }) => (
        <div className={cx(lang === 'ar' ? 'mr-auto' : 'ml-auto')}>
          <Button
            text={translate(translations.EXPORT)}
            size="medium"
            variant="tertiary"
            startIcon={<DownloadIcon width="20px" color={colors.blue[700]} />}
            data-testid="export-orders"
            disabled={isExporting}
            onClick={() => {
              const selectedStatus =
                ordersVariables?.status ||
                (ordersVariables?.statuses && ordersVariables?.statuses[0]) ||
                (typeof ordersVariables?.paymentStatuses === 'string' && ordersVariables?.paymentStatuses) ||
                (Array.isArray(ordersVariables?.paymentStatuses) &&
                  ordersVariables?.paymentStatuses &&
                  ordersVariables?.paymentStatuses[0]) ||
                ORDER_STATUS.ALL;

              return open({
                title: (
                  <div className={cx('flex justify-between items-center text-lg', lang === 'ar' && 'flex-row-reverse')}>
                    <Text value={translations.ALL} />
                  </div>
                ),
                body: (
                  <>
                    <Formik
                      initialValues={{
                        all: !canExportWFilters,
                        products_included: false,
                      }}
                      onSubmit={async data => {
                        if (data) {
                          if (!data.all) {
                            exportOrders({
                              ...ordersVariables,
                              products_included: data.products_included,
                            });
                          } else {
                            exportOrders({
                              status: ordersVariables?.status,
                              paymentStatuses: ordersVariables?.paymentStatuses,
                              statuses: ordersVariables?.statuses,
                              all: selectedStatus === ORDER_STATUS.ALL,
                              products_included:
                                selectedStatus === ORDER_STATUS.ALL || selectedStatus === ORDER_STATUS.FULFILLED
                                  ? false
                                  : data.products_included,
                            });
                          }
                          close();
                        }
                      }}
                    >
                      {({ values }) => (
                        <Form>
                          <div className="mb-0 md:mb-12 pt-4">
                            <Field
                              name="all"
                              items={[
                                {
                                  value: false,
                                  title: <Text value={translations.EXPORTـALL_FILTERED_ORDERS} />,
                                  disabled: !canExportWFilters,
                                  tooltip: translate(translations.CANT_EXPORT_FILTERED),
                                },
                                {
                                  value: true,
                                  title: allOrderStatuses.find(status => status.id === selectedStatus).title,
                                },
                              ]}
                              elementClassName={`mx-4 mb-4 ${lang === 'ar' && 'flex-row-reverse mr-4'}`}
                              containerStyle={{ marginBottom: '0.25rem' }}
                              component={RadioList}
                              tooltip={
                                <ReactTooltip
                                  id="main-radio-tooltip"
                                  place="bottom"
                                  style={{ maxWidth: 'fit-content', zIndex: '10' }}
                                  hidden={canExportWFilters}
                                />
                              }
                            />
                            <div
                              data-tooltip-id="main"
                              data-tooltip-content={translate(translations.CANT_INCLUDE_DETAILS_TOOLTIP)}
                              data-class="max-w-xs"
                            >
                              <Field
                                name="products_included"
                                containerClassName={`flex items-start mx-3 ${lang === 'ar' && 'flex-row-reverse'}`}
                                checkboxClassName="flex items-start sm:items-center justify-center mx-2"
                                title={<Text className="text-gray-700" value={translations.INCLUDE_DETAILS} />}
                                disabled={
                                  values.all &&
                                  (selectedStatus === ORDER_STATUS.ALL || selectedStatus === ORDER_STATUS.FULFILLED)
                                }
                                component={CheckboxAlt}
                              />
                            </div>
                            <ReactTooltip
                              id="main"
                              place="bottom"
                              type="dark"
                              effect="solid"
                              style={{ maxWidth: 'fit-content' }}
                              disable={
                                !(
                                  values.all &&
                                  (selectedStatus === ORDER_STATUS.ALL || selectedStatus === ORDER_STATUS.FULFILLED)
                                )
                              }
                            />
                          </div>
                          <Footer modalFooter>
                            <div className="ml-auto">
                              <div className="w-full flex items-center">
                                <span className="mx-2">
                                  <Button
                                    testId="busy-cancel-btn"
                                    onClick={close}
                                    variant="tertiary"
                                    text={translate(translations.CANCEL)}
                                  />
                                </span>
                                <Button
                                  testId="busy-set-btn"
                                  isLoading={isExporting}
                                  variant="primary"
                                  type="submit"
                                  text={translate(translations.EXPORT)}
                                />
                              </div>
                            </div>
                          </Footer>
                        </Form>
                      )}
                    </Formik>
                  </>
                ),
                size: 'max-w-md',
              });
            }}
          />
        </div>
      )}
    </Modal>
  );
};

ExportOrders.propTypes = {
  storeId: PropTypes.string.isRequired,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  canExportWFilters: PropTypes.bool,
};
export default ExportOrders;
